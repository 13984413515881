<template>
  <button
    :class="['appearance-none', computedClasses, btnClass]"
    v-on="$listeners"
    :type="type"
    :disabled="disabled"
    class="" 
  >
    <template v-if="icon">
      <slot name="icon">
        <component :is="icon" v-bind="iconOption" />
      </slot>
    </template>
    <template v-if="loading">
      <slot name="loader">
        <div class="flex justify-center  w-button csm:hidden clg:flex ">
        <div class="loader"></div>
        </div>
         <div class="flex justify-center clg:hidden csm:flex   ">
        <div class="loader"></div>
        </div>
      </slot>
    </template>
    <template v-else >
      <div class="clg:hidden csm:flex ">
        <div >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.80584 3.71212C10.172 3.346 10.7655 3.346 11.1317 3.71212L16.7567 9.33712C17.1228 9.70324 17.1228 10.2968 16.7567 10.6629L11.1317 16.2879C10.7655 16.6541 10.172 16.6541 9.80584 16.2879C9.43972 15.9218 9.43972 15.3282 9.80584 14.9621L14.7679 10L9.80584 5.03794C9.43972 4.67183 9.43972 4.07823 9.80584 3.71212Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M2.96875 10C2.96875 9.48223 3.38848 9.0625 3.90625 9.0625H15.3125C15.8303 9.0625 16.25 9.48223 16.25 10C16.25 10.5178 15.8303 10.9375 15.3125 10.9375H3.90625C3.38848 10.9375 2.96875 10.5178 2.96875 10Z" fill="white"/>
</svg>
      </div>
      </div>
      <div>
      <span class="csm:hidden clg:flex " v-if="text" v-html="text"></span>
      </div>
    </template>
  </button>
</template>


<script>
//import Loader from "./Loader.vue";
export default {
  components: {
    //Loader,
  },
  props: {
    type: {
      type: String,
      default: "button",
    },
    color: {
      type: String,
      default: "",
    },

    outline: {
      type: Boolean,
      default: false,
    },

    rounded: {
      type: Boolean,
      default: true,
    },

    full: {
      type: Boolean,
      default: false,
    },

    btnClass: {
      type: String,
      default: "",
    },

    btnHeight: {
      type: String,
      default: "h-42fl",
    },

    text: {
      type: String,
      default: "",
    },

    icon: {
      type: String,
      default: "",
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    applyDisabledColor: {
      type: Boolean,
      default: false,
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return [
        "outline-none focus:outline-none",
        this.btnColors,
        this.rounded && "rounded",
        this.full && "w-full",
        this.btnHeight,
        this.loading && "loading",
        this.disabled && this.applyDisabledColor && "disabled",
      ];
    },

    btnColors() {
      let color = "";
      switch (this.color) {
        case "primary":
          color = this.outline
            ? "bg-transparent border-2 border-kblue-300 text-kblue-300 hover:border-kblue-200 hover:text-kblue-200"
            : "text-white bg-kblue-300 hover:bg-kblue-300";
          break;
        case "primary-dark":
          color = this.outline
            ? "bg-transparent border border-bgk-4 text-bgk-4 hover:border-bgk-4 hover:text-bgk-4"
            : "text-white bg-bgk-4 hover:bg-bgk-4";
          break;

        case "secondary":
          color = this.outline
            ? " rounded-md bg-transparent  text-kbutton-1  h-42fl  hover:border-kbutton-2 hover:text-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 px-4 sm:px-6 text-sm sm:text-base focus:outline-none"
            : "text-white  h-42fl bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 px-4 sm:px-6 text-sm sm:text-base focus:outline-none";
          break;
        case "transparent":
          color = this.outline
            ? " rounded-md bg-transparent  text-kbutton-1   hover:border-kbutton-2 hover:text-kbutton-2 active:border active:border-kbutton-3 py-1 sm:py-2 px-10  sm:px-6 text-sm sm:text-base focus:outline-none"
            : "text-black  w-button  h-42fl bg-kbutton-4 hover:bg-kbutton-4 active:border active:border-kbutton-4 px-c60 sm:px-6 text-sm sm:text-base focus:outline-none";
          break;
        default:
          color = this.color;
      }
      return color;
    },
  },
};
</script>

<style lang="postcss" scoped>


.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #e21a24;
  width: 25px;
  height: 25px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loading {
  @apply pointer-events-none text-transparent;
}
.disabled {
  @apply pointer-events-none bg-compteur-500 text-tex-11 border-compteur-500 cursor-not-allowed;
}
</style>