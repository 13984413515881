<template>
  <div class="relative">
    <header>
      <Khead />
    </header>

    <div :class="closed ? 'message ' : 'messageHide'">
      <div class="flex justify-center items-center p-2">
        <BlueInfo />
        <span class="text-black" style="font-weight: 600px">
          {{ $t("kkrbjlp.info") }}
        </span> 
        <div class="ml-2">
          <div class="">
            <router-link
              to="/find"
              class="cursor-pointer underline"
              style="font-weight: 600px; color: #2d7bf7"
            >
              {{ $t("kkrbjlp.find") }}
            </router-link>
          </div>
        </div>
        <a @click="closeBlue()" class="cursor-pointer ml-2">
          <Close />
        </a>
      </div>
    </div>
    <div
      class="flex flex-col bg-kinput-5 bg-right-top csm:bg-step-ellips-sizem"
    >
      <div class="max-w-lpw mx-auto">
        <div
          class="
            grid
            gap-c4
            csm:my-12 csm:items-center
            clg:grid-cols-csc
            justify-center
            my-8
            csm:w-11/12
            clg:w-83p
            mx-auto
          "
        style="height: 100%; !important "

        >
          <ServiceRecharge
            v-if="!paymentStep"
            link="#"
            image="landingPage/assets/illustration.svg"
            :description="$t('kkrbjlp.firstServiceDescription')"
            :title="$t('kkrbjlp.firstServiceTitle')"
          />

          <PayInvoice
            v-if="!paymentStep"
            link="#"
            image="landingPage/assets/comp.svg"
            :title="$t('kkrbjlp.title')"
            :description="$t('kkrbjlp.secondServiceDescription')"
          />
        </div>
      </div>
    </div>

    <div
      class="
        flex flex-col
        bg-kblue-300 bg-right-top bg-step-ellips-size
        csm:bg-step-ellips-sizem
      "
    >
      <div class="max-w-lpw mx-auto">
        <div
          class="
            flex flex-col
            clg:flex-row
            csm:justify-center
            clg:justify-between
            items-center
            csm:w-11/12
            clg:w-83p
            mx-auto
          "
        >
          <div>
            <SofaGuy class="sofa-web" />
          </div>
          <div
            class="
              csm:hidden
              clg:flex
              flex flex-col flex-nowrap
              csm:ml-6
              clg:mt-0
              csm:w-full
              clg:w-50p
            "
          >
            <!-- step -->
            <div class="py-10 z-10 flex flex-col">
              <p
                class="
                  text-white text-40fl
                  tracking-c08
                  not-italic
                  font-bold
                  m-0
                "
                style="font-weight: 600; font-size: 28px; line-height: 32px"
              >
                {{ $t("kkrbjlp.sofaText") }}
              </p>
              <p
                class="text-white mt-c24"
                style="font-size: 20px; line-height: 22px"
              >
                {{ $t("kkrbjlp.paymentMethod") }}
              </p>

              <div class="mt-2 flex">
                <IconGroupPay />
              </div>
            </div>
            <!-- step -->
          </div>
          <div
            class="
              csm:flex
              clg:hidden
              flex flex-col flex-nowrap
              csm:ml-6
              clg:mt-0
              csm:w-full
              clg:w-50p
            "
            style="padding-left: 7%"
          >
            <!-- step -->
            <div class="py-10 z-10 flex flex-col">
              <p
                class="
                  text-white text-40fl
                  tracking-c08
                  not-italic
                  font-bold
                  m-0
                "
                style="font-weight: 600; font-size: 24px; line-height: 32px"
              >
                {{ $t("kkrbjlp.sofaText") }}
              </p>
              <p
                class="text-white mt-c24"
                style="font-size: 20px; line-height: 22px"
              >
                {{ $t("kkrbjlp.paymentMethod") }}
              </p>

              <div class="mt-2 flex">
                <IconGroupPay />
              </div>
            </div>
            <!-- step -->
          </div>
        </div>
      </div>
      <div class="clg:flex csm:hidden" style="position: absolute; right: 0">
        <img src="landingPage/assets/Ellipse.svg" alt="" />
      </div>
      <div class="csm:flex clg:hidden" style="position: absolute; left: 0">
        <img src="landingPage/assets/ellipse.svg" alt="" />
      </div>
      <!-- <div class="csm:hidden clg:flex" style="position: absolute; bottom: 0; right: 20%; ">
          <img src="landingPage/assets/Frame.svg" alt="" />
        </div> -->
    </div>

    <Illustration>
      <span style="font-weight: 600; font-size: 24px; line-height: 40px">{{
        $t("kkrbjlp.moreThan20")
      }}</span>
    </Illustration>
    <footer>
      <Kfooter />
    </footer>
  </div>
</template>

<script>
import Khead from "components/landingPage/partials/Khead.vue";
import Kfooter from "components/landingPage/partials/Kfooter.vue";
import IconGroupPay from "components/landingPage/IconGroupPay.vue";
import SofaGuy from "components/landingPage/sofaGuy.vue";
import BlueInfo from "components/landingPage/blueInfo.vue";
import Close from "components/landingPage/close.vue";
import Illustration from "components/landingPage/illustration.vue";
import { mapGetters } from "vuex";
import ServiceRecharge from "components/landingPage/serviceRecharge";
import PayInvoice from "components/landingPage/PayInvoice";
export default {
  components: {
    SofaGuy,
    Khead,
    Kfooter,
    IconGroupPay,
    PayInvoice,
    BlueInfo,
    Close,
    ServiceRecharge,
    Illustration,
  },
  data() {
    return {
      modalContentIndex: 0,
      openModal: false,
      closed: true,
      signMenu: false,
    };
  },
  computed: {
    ...mapGetters(["getFirstModal"]),

    findReloadStep() {
      return this.$store.getters.getFindReloadStep;
    },
    paymentStep() {
      return this.$store.getters.getPaymentStep;
    },
    pleaseWaitStep() {
      return this.$store.getters.getPleaseWaitStep;
    },
  },
  mounted() {
    this.$store.commit("mutPaymentStep", false);
    this.$store.commit("mutPleaseWaitStep", false);
    this.$store.commit("mutFindReloadStep", false);
  },
  methods: {
    showFindReload() {
      this.$store.commit("mutFindReloadStep", true);
    },
    readContent() {
      return this.modalContents[this.modalContentIndex];
    },

    closeModal() {
      this.openModal = false;
    },
    closeBlue() {
      console.log(this.closed, "nnnnnnnnnnnn-----");
      return (
        (this.closed = false), console.log(this.closed, "nnnnnnnnnnnn-----")
      );
    },
  },
};
</script>

<style scoped>
.bg-frame-916 {
  width: 117.5px;
  height: 113.75px;
  top: -2.5rem;
  right: -2.281rem;
}

.textHide {
  visibility: hidden;
}

@media (min-width: 640px) {
  .sofa-web {
    position: relative;
  }
}
.modal {
  width: 100%;
  justify-content: center;
  height: 100vh;
  z-index: 30;
  background-color: rgba(0, 0, 0, 0.665);
  padding: 80px;
}
.sbeeicon {
  width: 2.477rem;
  height: 2.477rem;
}

.messageHide {
  max-height: 0px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.message {
  max-height: 40px;

  gap: 5px;
  color: #1e3b71;
  transition: all 0.5s ease-in-out;
  background-color: #eef2fa;
}

@media (max-width: 767px) {
  .sofa-web {
    z-index: 99999;
    position: relative;
    padding-bottom: 10px;
  }
  .modal {
    height: 100vh;
    justify-content: center;
  }
  .sbeeicon {
    width: 1.773rem;
    height: 1.773rem;
  }
  .message{
    font-size: 11px;
  }
}
</style>
