<template>
  <div>
    <div>
     
      <ClosableModal
          :open="open"
          :title="$t('kkrbjlp.meterTest')"
          :desc="$t('kkrbjlp.meterDescription')"
          @close="reset"
        >
          <template v-slot:content>
            <div class="overflow-auto">
              <img
                src="landingPage/assets/Rectangle.webp"
                style="max-height: 55vh"
                class="w-full img-web"

              />

              <div>
                <img
                  src="landingPage/assets/Rectangle.webp"
                  style="max-height: 55vh"
                  class="w-full img-mobile"
  
                />
              </div>

              <div class="flex justify-center my-2 mt-2">
                <button
                  class="bg-tex-2 py-3  px-6 rounded text-white"
                  @click="open= false"
                >
 {{$t('kkrbjlp.finished')}}
                </button>
              </div>
            </div>
          </template>
        </ClosableModal>
    </div>

    <div>
    <div
        class="
          bg-white
          flex
          px-2
          rounded
          flex-shrink-1
          justify-start
          items-start
        "
      
      >
        <div class="csm:hidden clg:flex py-2 rounded-full flex items-center">
          <img :src="image" class="" style="width: 100px; height: 100px" />
        </div>
        <div class="px-2 py-6">
          <div
            class="clg:hidden csm:flex rounded-full flex items-center"
            style="width: 60px; height: 60px"
          >
            <img :src="image" class="" />
          </div>
        </div>

        <div class="py-4">
          <div class="py-1">
            <span
              v-html="title"
              class="
                tracking-m0c5
                text-bgk-4
                clg:text-24fl
                leading-26l
                csm:text-xl
                font-bold
              "
            ></span>
          </div>
          <div>
            <div class="py-1 csm:hidden clg:flex">
              <span
                v-html="description"
                class="text-tex-1"
                style="font-size: 16px; line-height: 18px"
              >
              </span>
            </div>
            <div class="py-1 clg:hidden csm:flex">
              <span
                v-html="description"
                class="text-tex-1"
                style="font-size: 14px; line-height: 18px"
              >
              </span>
            </div>
            <div class="py-1">
              <form @submit.prevent="passCountPay">
                <div class="flex">
                  <div class="flex flex-col">
                    <input
                      :placeholder="$t('kkrbjlp.placeholder')"
                      v-model="counterNum"
                      class="
                        p-3
                        h-42fl
                        border-1.5 border-kborder-500
                        hover:border-kinput-3
                        focus:border-kinput-2
                        rounded
                        w-full
                        leading-tight
                        focus:outline-none focus:bg-white
                      "
                      id="compId"
                      name="compId"
                      @input="resetValidation"
                      oninput="if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      type="number"
                      min="0"
                      maxlength="20"
                      v-validate="'required|numeric'"
                      :class="{
                        ' border-tex-2': submitted && errors.has('compId'),
                      }"
                    />

                    <p
                      v-show="submitted && errors.has('compId')"
                      class="text-tex-2"
                    >
                      {{ $t("counterRecharge.validate") }}
                    </p>
                    <p v-show="invalidMeter" class="text-tex-2">
                      {{ $t("counterRecharge.invalidMeter") }}
                    </p>
                    <p v-show="blockedMeter" class="text-tex-2 text-sm">
                      {{ $t("counterRecharge.blockedMeter") }}
                    </p>
                  </div>
                  <div class="ml-4">
                    <base-button
                      color="secondary"
                      :applyDisabledColor="!loading"
                      type="submit"
                      :text="$t('counterRecharge.btn')"
                      :loading="loading"
                    />
                  </div>
                </div>
              </form>
            </div>

            <div class="items-center mb-4">
              <div>
                <a
                  @click="toggleModal"
                  class="underline cursor-pointer question"
                  style="color: #2d7bf7; font-weight: 600; font-size: 14px"
                >
                  {{ $t("kkrbjlp.moreKnow") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import IconCompt2 from "components/landingPage/IconCompt2.vue";
import BaseButton from "components/BaseButtonm.vue";
import axios from "axios";
import ClosableModal from "components/landingPage/modal";

export default {
  components: {
    //  IconCompt2,
    ClosableModal,
    BaseButton,
  },
  data() {
    return {
       imageLoaded: false,
      open: false,
      loading: false,
      submitted: false,
      invalidMeter: false,
      blockedMeter: false,
      hasNotEnougthMoney: false,
      counterNum: "",
    };
  },
  props: {
    title: String,
    link: String,
    description: String,
    image: String,
  },

  methods: {
    imgLoadEnd() {
      this.imageLoaded = true;
    },
    reset() {
      this.open = false;
    },
    toggleModal() {
      this.open = true;
    },
    passCountPay() {
      this.counterNum = String(this.counterNum).toUpperCase();
      this.invalidMeter = false;
      this.blockedMeter = false;
      this.submitted = true;
      this.$validator.validate().then((valid) => {
        if (valid) {
          this.loading = true;
          axios
            .post("/transaction", {
              meterNumb: this.counterNum,
              amount: "1",
              userId: this.$store.getters.getAuthUser.id,
            })
            .then(async (res) => {
              console.log("Where Am I ???");
              const data = res.data;
              const debt = parseFloat(data.arrearAMT) + parseFloat(data.feeAMT);
              const meter = {
                number: data.meterNum,
                user: data.customerName,
                debt: debt,
                transacId: data.transID,
              };
              this.$store.commit("mutCounterFirstPur", meter);
              await this.$store.commit("mutPaymentStep", true);
              await this.$store.commit('mutBillProcessBoolean', false)
              //redirect to /recharges ?
              if (this.$route.path === "/") this.$router.push("recharges");
              //
              if (this.hasNotEnougthMoney) {
                this.hasNotEnougthMoney = false;
              }
            })
            .catch((err) => {
              console.log("I am were ???");
              // const message = err.response.data.message;
              if (err.response) {
                // console.log('error response --->', err.response);
                const data = err.response.data;
                //   console.log('data: ', data);
                if (data.state == "-10006") {
                  // console.log('Invalid ====>');
                  this.invalidMeter = true;
                } else if (data.locked == "meter blocked") {
                  // console.log('blockedMeter ====>');
                  this.blockedMeter = true;
                } else if (data.state == "-10023") {
                  this.hasNotEnougthMoney = true;
                }
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetValidation() {
      if (this.invalidMeter) {
        this.invalidMeter = false;
      }
      if (this.blockedMeter) {
        this.blockedMeter = false;
      }
    },

    showFindReload() {
      this.$store.commit("mutFindReloadStep", true);
    },
  },
  mounted() {
    this.loading = false;
    this.counterNum = this.$store.getters.getPrevCounterNumber;
  },
};
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
.img-mobile {
  display: none;
}

.img-web {
  display: block;
}
@media (max-width: 767px) {
   .img-web {
    display: none;
  }
  
  .img-mobile {
    display: block;
  }
  .question {
    font-size: 12px;
  }
}
</style>