<template>
  <div class="h-auto text-center" style="background:#F5F6F6;">
    <div class="p-4 h-auto flex flex-row items-center justify-center w-full" style="background:#222F5A;">
      <div class="flex csm:flex-col clg:flex-row justify-center py-4 items-center csm:w-11/12 clg:w-11/12 wide:w-83p max-w-lpw mx-auto">
        <div>
          <p class="text-white clg:text-30fl leading-32l text-base font-semibold clg:mr-8 text-center csm:mb-6 clg:mb-0">
            {{ $t("footer.title") }}
          </p>
        </div>
        <div>
          <a href="https://kkiapay.me" target="blank">
            <button class="text-white text-base px-8 py-2 w-full rounded bg-kbutton-1 hover:bg-kbutton-2 active:border active:border-kbutton-3 focus:outline-none">{{ $t("footer.titleBtn") }}</button>
          </a>
        </div>
      </div>
    </div>
    <div class="p-4 flex flex-col items-center">
      <div class="flex flex-col clg:flex-row items-center mb-8">
        <p class="csm:hidden clg:text-24l clg:leading-26l font-semibold mr-4 mb-4" style="color:#333333;font-weight: 600;
font-size: 24px;
line-height: 26px;">
          {{ $t("footer.plateformeAgree") }}
        </p>
        <p class="clg:hidden text-base font-semibold mr-4 mb-4" style="color:#333333;">
          {{ $t("footer.plateformecertified") }}
        </p>
        <div class="flex mb-4 sm:mb-0"><Pci /></div>
      </div>
      <div class="max-w-lpw w-full">
      <div class="mx-auto csm:w-11/12 clg:w-75p wide:max-w-72p flex flex-col clg:flex-row clg:justify-between text-left mb-8">
        <div class="text-center clg:text-left my-3 mb-8 clg:m-0">
          <h5 class="text-sm sm:text-xl font-semibold" style="color:#333333">
            {{ $t("footer.about") }}
          </h5>
          <ul class="mt-1" style="color: #4D4C4C">
            <li><a href="https://kkiapay.me/entreprise" target="blank">{{ $t("footer.entreprise") }}</a></li>
            <li><a href="https://kkiapay.me/conformite" target="blank">{{ $t("footer.autorisation") }}</a></li>
            <li><a href="https://kkiapay.me/termes-and-conditions" target="blank">{{ $t("footer.condition") }}</a></li>
          </ul>
        </div>
        <div class="text-center clg:text-left my-3 mb-8 clg:m-0">
          <h5 class="text-sm sm:text-xl font-semibold" style="color:#333333">
            {{ $t("footer.kkiapayAbout") }}
          </h5>
          <ul class="mt-1" style="color: #4D4C4C">
            <li><a href="https://kkiapay.me/pricing" target="blank">{{ $t("footer.kOffers") }}</a></li>
            <li><a href="https://app.kkiapay.me/sign-up/" target="blank">{{ $t("footer.createAccount") }}</a></li>
          </ul>
        </div>
        <div class="text-center clg:text-left my-3 mb-8 clg:m-0">
          <h5 class="text-sm sm:text-xl font-semibold" style="color:#333333">
            {{ $t("footer.helpCenter") }}
          </h5>
          <ul class="mt-1" style="color: #4D4C4C">
            <li>
              <button onclick="$crisp.push(['do', 'chat:toggle'])" class="focus:outline-none">
                {{ $t("footer.askQuiz") }}
              </button>
            </li>
            <li><a href="mailto:support@kkiapay.me">{{ $t("footer.writeUs") }}</a></li>
          </ul>
        </div>
      </div>
      </div>
      <div style="margin-left:11%">
        <div class="flex flex-col items-center text-center clg:flex-row">
          <p class="text-base sm:text-sm font-semibold clg:mr-2" style="color:#333333; ">
            {{ $t("footer.trademarque") }}
          </p>
          <a href="http://opensi.co/" target="blank"><OpenSi /></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpenSi from 'components/landingPage/OpenSi.vue'
import Pci from 'components/landingPage/Pci.vue'
export default {
  components: {
    OpenSi,
    Pci
  }
}
</script>

<style scoped>
.pkk{
font-weight: 600;
font-size: 30px;
line-height: 32px;
font-family: Source Sans Pro;
font-style: normal;
}
</style>
