<template>

 <div>
     <ClosableModal
          :open="open"
         
          :title="$t('kkrbjlp.subscribeNumber')"
          :desc="$t('kkrbjlp.subscribeTest')"
          @close="reset1"
        >
          <template v-slot:content>
            <div class="overflow-auto">
              <img
                src="landingPage/assets/facture_sbee.webp"
                style="max-height: 55vh"
                class="w-full img-web"
               
              />

              <div>
                <img
                  src="landingPage/assets/facture_sbee.webp"
                  style="max-height: 55vh"
                  class="w-full img-mobile"
                 
                />
              </div>

              <div class="flex justify-center my-2 mt-2">
                <button
                  class="bg-tex-2 py-3  px-6 rounded text-white"
                  @click="open = false"
                >
                 {{$t('kkrbjlp.finished')}}
                </button>
              </div>
            </div>
          </template>
        </ClosableModal>
   <div class="bg-white flex  px-2 rounded flex-shrink-1
      justify-start 
      items-start" style="width: ;">
    <div class=" csm:hidden clg:flex  rounded-full flex items-center   " >
        <img :src="image" class="" style="width:150px; height:125px"/>
    </div>
    <div class="px-2 py-6">
      <div class=" clg:hidden csm:flex rounded-full flex items-center   " style="width:60px; height:60px">
        <img :src="image" class=""/>
    </div>
    </div>
    
   <div class="py-4">
     
     <div class="py-1">
          <span v-html="title"
            class="
              tracking-m0c5
              text-bgk-4
              clg:text-24fl
              leading-26l
              csm:text-xl
              font-bold
            "
            
            ></span
          >
        </div>
         <div>
          <div class="py-1 csm:hidden clg:flex">
            <span v-html="description" class="text-tex-1" style="font-size: 16px;
line-height: 18px;">
            
              
            </span>
          </div>
          <div class="py-1 clg:hidden csm:flex">
            <span v-html="description" class="text-tex-1" style="font-size: 14px;
line-height: 18px;">
            
              
            </span>
          </div>
           <div class="clg:hidden csm:flex items-center">
           <div class="flex items-center">
              <a :href="link" class="underline cursor-pointer" style="color: #2d7bf7;font-weight: 600;
font-size: 16px;"
              >{{ $t('kkrbjlp.moreKnow')}}</a
            >
            <a :href="link" class="ml-2   cursor-pointer">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.872129 0.340848C1.20163 0.0113433 1.73587 0.0113433 2.06537 0.340848L7.12787 5.40335C7.45738 5.73285 7.45738 6.26708 7.12787 6.59659L2.06537 11.6591C1.73587 11.9886 1.20163 11.9886 0.872129 11.6591C0.542624 11.3296 0.542624 10.7954 0.872129 10.4658L5.33801 5.99997L0.872129 1.53409C0.542624 1.20459 0.542624 0.670353 0.872129 0.340848Z"
                  fill="#2D7BF7"
                />
              </svg>
            </a>
           </div>
          </div>
          <div class="py-1">
            <form @submit.prevent="passCountPay">
              <div class="flex " >
                <div class="flex flex-col">
                  <input
                  
                    :placeholder="$t('kkrbjlp.payplaceholder')"
                    v-model="subscriberNumber"
                    class=" 
                     uppercase
                      p-3
                      h-42fl
                      border-1.5 border-kborder-500
                      hover:border-kinput-3
                      focus:border-kinput-2
                      rounded
                      w-full
                      leading-tight
                      focus:outline-none focus:bg-white
                    "
                    
                    id="compId"
                    name="compId"
                    @input="resetValidation"
                    maxlength="20"
                    v-validate="'required'"
                    :class="{
                      ' border-tex-2': submitted && errors.has('compId'),
                    }"
                  />

                  <p v-show="submitted && errors.has('compId')" class="text-tex-2">
                    {{ $t("counterRecharge.validateSubscribeNumb") }}
                  </p>
                  <p v-show="invalidMeter" class="text-tex-2">
                    {{ $t("counterRecharge.invalidSubscribeNumb") }}
                  </p>
                </div>
                <div class="ml-4">
                  <base-button
                  color="secondary"
                  :applyDisabledColor="!loading"
                  type="submit"
                  :text="$t('counterRecharge.btn')"
                  :loading="loading"
                />
                </div>
                
              </div>
            </form>
          </div>
          <div class="csm:hidden clg:flex items-center">
             <div class="flex items-center">
              <a @click="toggleModal" class="underline cursor-pointer question"
                  style="color: #2d7bf7; font-weight: 600; font-size: 14px"
              >
                {{ $t('kkrbjlp.subNumber')}}
              </a
            >
            
           </div>
          </div>
          </div>
   </div>
  
 </div>
 </div>
</template>

<script>
//import IconCompt2 from "components/landingPage/IconCompt2.vue";
import ClosableModal from "components/landingPage/modal";

import BaseButton from "components/BaseButtonm.vue";
import {billRechargeApi} from '@/main'

export default {
  components: {
  //  IconCompt2,
  BaseButton,
  ClosableModal
  },
  data() {
    return {
      lang:'fr',
        imageLoaded: false,
      open: false,
      loading: false,
      submitted: false,
      invalidMeter: false,
      subscriberNumber: "",
    };
  },
  props:{
      title:String,
      link:String,
      description:String,
      image:String

  },

  methods: {
     toggleModal() {
      this.open = true;
    },
    reset() {
      this.open = false;
    },
    imgLoadEnd() {
      this.imageLoaded = true;
    },
    passCountPay() {
      this.subscriberNumber = String(this.subscriberNumber).toUpperCase();
      this.lang= this.lang.substr(0,2)
     if (this.lang != 'fr') {
       this.lang = 'en'
       
     }
      this.invalidMeter = false;
      this.submitted = true;
      if(/^\d{12}[A-Z]{2}$/g.test(this.subscriberNumber)) {
        this.$validator.validate().then((valid) => {
        if (valid) {
          this.loading = true;
                          if (this.$route.path === "/") this.$router.push(`/recharges?ref=${this.subscriberNumber}&local=${this.lang}`);
          billRechargeApi
            .get(`/subscribers?reference=${this.subscriberNumber}&local=${this.lang}`)
            .then(async (res) => {
              const data = res.data;
              console.log('data: ', data);
              if (data.status) {
                data.status == 422 ? this.invalidMeter = true : ''
              } else {
                this.$store.commit("mutBillProcessFirstPur", data);
                await this.$store.commit('mutBillProcessBoolean', true)
                await this.$store.commit("mutPaymentStep", true);
                console.log(this.$route.path);
                //redirect to /recharges ?
                if (this.$route.path === "/") this.$router.push(`/recharges?ref=${this.subscriberNumber}&local=${this.lang}`);
              }
              
            })
            .catch((err) => {
              if (err.response) {
                const data = err.response.data;
                console.log('errdata: ', data);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
      }
    },
    resetValidation() {
      if (this.invalidMeter) {
          this.invalidMeter = false
      }
    },
  },
  mounted() {
     this.lang = navigator.language
     console.log('lang',this.lang.substr(0,2));
    this.loading = false;
    this.subscriberNumber = this.$store.getters.getPrevCounterNumber;
  },
};
</script>

<style>
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}
@media (max-width: 767px) {
   .img-web {
    display: none;
  }
  
  .img-mobile {
    display: block;
  }
  .question {
    font-size: 12px;
  }
}
::placeholder{
   text-transform: capitalize;
}

</style>
