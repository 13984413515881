<template>
<div class="w-full bg-white">
  <div class="max-w-lpw mx-auto">
    <div class="bg-white flex justify-between px-0 py-4 mx-auto csm:w-11/12 clg:w-83p">
      <router-link to="/">
        <Iconkkiapay class="csm:hidden"/>
        <Iconkkiapaysm class="clg:hidden" />
      </router-link>
      <div class="flex flex-nowrap items-center">
        <router-link to="/recharges/register">
          <hButton class=" csm:ml-2" :text="$t('sign.register')"/>
        </router-link>
        <router-link class="ml-4" to="/recharges/signsuccess">
          <lButton :text="$t('sign.login')" />
        </router-link>
        
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Iconkkiapay from 'components/landingPage/Iconkkiapay.vue'
import Iconkkiapaysm from 'components/landingPage/Iconkkiapaysm.vue'
import hButton from 'components/landingPage/hButton.vue'
import lButton from 'components/landingPage/lButton.vue'

export default {

  components: {
    Iconkkiapay,
    Iconkkiapaysm,
    hButton,
    lButton
  },

  data () {
    return {

      signMenu: false
    }
  }
}
</script>

<style scoped>

</style>
